<h1 mat-dialog-title>Keyboard Shortcuts <xpo-dialog-close-button></xpo-dialog-close-button></h1>

<div mat-dialog-content class="app-KeyboardShortcuts">
  <div *ngFor="let section of sections">
    <h4>{{ section }}</h4>

    <table mat-table [dataSource]="getShortcuts(section)" class="app-KeyboardShortcuts__board">
      <ng-container matColumnDef="text">
        <td mat-cell *matCellDef="let element">{{ element.text }}</td>
      </ng-container>

      <ng-container matColumnDef="keyAlternatives">
        <td mat-cell class="app-KeyboardShortcuts__board__alternatives" *matCellDef="let element">
          <div
            class="app-KeyboardShortcuts__board__alternatives__shortcut"
            *ngFor="let keys of element.keyAlternatives; index as i"
          >
            <span
              class="app-KeyboardShortcuts__board__alternatives__shortcut__keycap"
              *ngFor="let key of keys.split(' + ')"
              >{{ key | keyboardKeyConverter }}</span
            >
            <span
              class="app-KeyboardShortcuts__board__alternatives__shortcut__separator"
              *ngIf="i < element.keyAlternatives.length - 1"
              >/</span
            >
          </div>
        </td>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>CANCEL</button>
</div>
