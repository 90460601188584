import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { Observable } from 'rxjs';
import { NotAuthorizedComponent } from '../../dialogs/not-authorized/not-authorized.component';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { UserRoleService } from '../../services/user-role/user-role.service';

@Injectable({
  providedIn: 'root',
})
export class RoleSwitchGuard implements CanActivate {
  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private config: ConfigManagerService,
    private userRoleService: UserRoleService,
    private dialog: MatDialog,
    private loggingService: LoggingApiService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    /** TODO: see user-role.service.ts on allowed roles... if a user has more than one allowed role,
     *        let them select the role to log in as.
     **/
    return new Promise((resolve, reject) => {
      const failGuardFunc = (showDialog: boolean) => {
        this.userRoleService.setUser(undefined);
        this.loggingService.clearContext('Role');
        this.loggingService.error(`User Not Authorized`);
        if (showDialog) {
          this.showNotAuthorizedDialog();
        }
        reject();
      };

      this.loggedInUserService
        .getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot))
        .subscribe(
          (user: User) => {
            if (this.userRoleService.isAuthorizedUser(user)) {
              this.userRoleService.setUser(user);
              // this.loggingService.setContext('Role', this.userRoleService.getRole());
              resolve(true);
              return;
            } else {
              console.log('not authorized');
              failGuardFunc(true);
            }
          },
          () => {
            failGuardFunc(false);
          }
        );
    });
  }

  private showNotAuthorizedDialog() {
    this.dialog.open(NotAuthorizedComponent, { disableClose: true });
  }
}
