import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { UserRoleService } from '../../services/user-role/user-role.service';

@Injectable({
  providedIn: 'root',
})
export class NotAuthorizedRedirectGuard implements CanActivate {
  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private config: ConfigManagerService,
    private router: Router,
    private userRoleService: UserRoleService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.loggedInUserService
      .getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(
        map((user: User) => {
          if (this.userRoleService.isAuthorizedUser(user)) {
            this.userRoleService.setUser(user);
            this.router.navigate(['/']);
            return true;
          }

          this.userRoleService.setUser(undefined);
          return false;
        })
      );
  }
}
