import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CityOperationsApiService,
  DeliverySpecialServiceAudit,
  GetDeliveryShipmentForAuditQuery,
  GetDeliveryShipmentForAuditResp,
  UpdateDeliveryShipmentFromAuditRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { EmployeeRoleCd, LogLevel } from '@xpo-ltl/sdk-common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import {
  GetUserPreferenceQuery,
  GetUserPreferenceResp,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import { DrMessageConfig } from 'app/dr-audit/components/dr-message/dr-message.component';
import { NotificationMessageStatus } from 'core';
import { NotificationMessageService } from 'core/services/notification-message.service';
import { UserRoleService } from 'core/services/user-role/user-role.service';
import { toUpper as _toUpper } from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { decode } from 'typescript-base64-arraybuffer';
import { FormFields } from '../enums/form-fields.enum';

export enum AuditDashboardActiveTab {
  Audit,
  Dashboard,
}

export enum ToggleAccessorial {
  InsideDelivery = FormFields.InsideDelivery,
  LiftgateRequired = FormFields.LiftgateRequired,
  Residential = FormFields.Residential,
  ConstructionUtlitySite = FormFields.ConstructionUtilitySite,
}

export enum Zoom {
  In,
  Out,
}

export interface Metrics {
  reviewedByYou: number;
  reviewedOverall: number;
  pending: number;
}

export interface Settings {
  zoom: number;
}

export interface InitialFormState {
  isInsideDelivery: boolean;
  isLiftgateRequired: boolean;
  isResidential: boolean;
  isConstructionUtilitySite: boolean;
  otherNotes: string;
}
@Injectable({
  providedIn: 'root',
})
export class DRAuditorService {
  private toggleAccessorialSubject = new BehaviorSubject<ToggleAccessorial>(undefined);
  readonly toggleAccessorial$ = this.toggleAccessorialSubject.asObservable();

  private focusOnNotesSubject = new BehaviorSubject(undefined);
  readonly focusOnNotes$ = this.focusOnNotesSubject.asObservable();

  private zoomValueSubject = new BehaviorSubject<number>(0.5);
  readonly zoomValue$ = this.zoomValueSubject.asObservable();

  private rotateValueSubject = new BehaviorSubject<number>(0);
  readonly rotateValue$ = this.rotateValueSubject.asObservable();

  private refreshSubject = new Subject<InitialFormState>();
  readonly refresh$ = this.refreshSubject.asObservable();

  private isRefreshingSubject = new BehaviorSubject<boolean>(false);
  readonly isRefreshing$ = this.isRefreshingSubject.asObservable();

  private isSubmittingSubject = new BehaviorSubject<boolean>(false);
  readonly isSubmitting$ = this.isSubmittingSubject.asObservable();

  private errorMessageSubject = new BehaviorSubject<DrMessageConfig>(undefined);
  readonly errorMessage$ = this.errorMessageSubject.asObservable();

  private metricsSubject = new BehaviorSubject<Metrics>({ reviewedOverall: 0, reviewedByYou: 0, pending: 0 });
  readonly metrics$ = this.metricsSubject.asObservable();

  private drImageSubject = new BehaviorSubject<Uint8Array>(undefined);
  readonly drImage$ = this.drImageSubject.asObservable();

  private previousShipmentInstIdSubject = new BehaviorSubject<number>(undefined);
  readonly previousShipmentInstId$ = this.previousShipmentInstIdSubject.asObservable();

  private readonly UserPreferencesKey = 'DR Audit Settings';

  private endOfWorkQueueSubject = new BehaviorSubject<boolean>(false);
  readonly endOfWorkQueue$ = this.endOfWorkQueueSubject.asObservable();

  private suppressKeyboardShortcutsSubject = new BehaviorSubject<boolean>(false);
  readonly suppressKeyboardShortcuts$ = this.suppressKeyboardShortcutsSubject.asObservable();

  private triggerSearchForProSubject = new Subject<void>();
  readonly triggerSearchForPro$ = this.triggerSearchForProSubject.asObservable();

  private auditDashboardActiveTabSubject = new BehaviorSubject<AuditDashboardActiveTab>(AuditDashboardActiveTab.Audit);
  readonly auditDashboardActiveTab$ = this.auditDashboardActiveTabSubject.asObservable();

  private firstFetch: boolean = true;

  private currentShipmentInstId: number = undefined;
  private currentProNumber: string = undefined;
  private currentProcessStartDateTime: Date = undefined;

  private previousProcessStartDateTime: Date = undefined;

  private drRetrievalTime: Moment = undefined;
  private drAcknowledgementTime: Moment = undefined;
  private formGroup: FormGroup;
  private isDoneBtnDisabledSubject = new BehaviorSubject<boolean>(false);
  readonly isDoneBtnDisabled$ = this.isDoneBtnDisabledSubject.asObservable();

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private userPreferencesService: UserPreferenceApiService,
    private loggingService: LoggingApiService,
    private clipboardService: ClipboardService,
    private notificationService: NotificationMessageService,
    private userRoleService: UserRoleService
  ) {}

  setAuditDashboardActiveTab(tab: AuditDashboardActiveTab): void {
    this.auditDashboardActiveTabSubject.next(tab);
  }

  triggerSearchForPro(): void {
    this.triggerSearchForProSubject.next();
  }

  setDoneBtnDisabled(data: boolean): void {
    this.isDoneBtnDisabledSubject.next(data);
  }

  toggleAccessorial(toggleAccessorial: ToggleAccessorial): void {
    if (!this.isRefreshingSubject.value && !this.suppressKeyboardShortcutsSubject.value) {
      this.toggleAccessorialSubject.next(toggleAccessorial);
    }
  }

  focusOnNotes(): void {
    if (!this.isRefreshingSubject.value) {
      this.focusOnNotesSubject.next(undefined);
    }
  }

  zoom(zoom: Zoom): void {
    let zoomValue: number = this.zoomValueSubject.value;
    if (zoom === Zoom.In) {
      zoomValue += 0.05;
      if (zoomValue > 2.0) {
        zoomValue = 2.0;
      }
    } else {
      zoomValue -= 0.05;
      if (zoomValue < 0.05) {
        zoomValue = 0.05;
      }
    }

    this.loggingService.log(
      LogLevel.INFO,
      `Zoom now set to ${zoomValue} on shipmentInstId ${this.currentShipmentInstId}`
    );
    this.zoomValueSubject.next(zoomValue);

    this.saveState();
  }

  skip(switchRoles: boolean = false): void {
    if (
      !switchRoles &&
      (this.isRefreshingSubject.value ||
        this.endOfWorkQueueSubject.value ||
        this.suppressKeyboardShortcutsSubject.value)
    ) {
      return;
    }

    if (switchRoles) {
      this.endOfWorkQueueSubject.next(false);
    }

    this.drAcknowledgementTime = moment.utc();

    this.loggingService.log(
      LogLevel.INFO,
      `Skip on shipmentInstId ${
        this.currentShipmentInstId
      }, Start ${this.drRetrievalTime?.format()} Stop ${this.drAcknowledgementTime?.format()}`
    );

    this.refresh(switchRoles);
  }

  previous(): void {
    if (
      this.isRefreshingSubject.value ||
      this.endOfWorkQueueSubject.value ||
      this.suppressKeyboardShortcutsSubject.value
    ) {
      return;
    }

    this.drAcknowledgementTime = moment.utc();

    this.loggingService.log(
      LogLevel.INFO,
      `Previous on shipmentInstId ${
        this.currentShipmentInstId
      }, Start ${this.drRetrievalTime?.format()} Stop ${this.drAcknowledgementTime?.format()}`
    );

    this.refresh(false, true);
  }

  submit(switchRoles: boolean = false): void {
    if (
      this.isRefreshingSubject.value ||
      this.endOfWorkQueueSubject.value ||
      this.suppressKeyboardShortcutsSubject.value ||
      this.isSubmittingSubject.value
    ) {
      return;
    }

    this.isSubmittingSubject.next(true);
    this.errorMessageSubject.next(undefined);

    this.drAcknowledgementTime = moment.utc();

    const insideDelivery = this.formGroup.get(FormFields.InsideDelivery).value;
    const liftgateRequired = this.formGroup.get(FormFields.LiftgateRequired).value;
    const residential = this.formGroup.get(FormFields.Residential).value;
    const constructionUtilitySite = this.formGroup.get(FormFields.ConstructionUtilitySite).value;
    const yourNotes = this.formGroup.get(FormFields.YourNotes).value;

    const request = {
      ...new UpdateDeliveryShipmentFromAuditRqst(),
      deliverySpecialServiceAudit: {
        ...new DeliverySpecialServiceAudit(),
        shipmentInstId: this.currentShipmentInstId,
        auditEmployeeId: this.userRoleService.getEmployeeId(),
        auditStartDateTime: this.drRetrievalTime?.toDate(),
        auditEndDateTime: this.drAcknowledgementTime?.toDate(),
        processStartDateTime: this.currentProcessStartDateTime,
        specialServiceInsideInd: insideDelivery,
        specialServiceLiftgateInd: liftgateRequired,
        specialServiceResidentialInd: residential,
        specialServiceConstructionInd: constructionUtilitySite,
        auditNote: yourNotes,
      },
    };

    this.cityOperationsService
      .updateDeliveryShipmentFromAudit(request)
      .pipe(
        take(1),
        finalize(() => this.isSubmittingSubject.next(false))
      )
      .subscribe(
        () => {
          this.loggingService.log(
            LogLevel.INFO,
            `Submit on shipmentInstId ${
              this.currentShipmentInstId
            }, Start ${this.drRetrievalTime?.format()} Stop ${this.drAcknowledgementTime?.format()}`
          );

          this.notificationService.openNotificationMessage(NotificationMessageStatus.Success, 'Submitted').subscribe();
          this.refresh(switchRoles);
        },
        (error) => {
          this.errorMessageSubject.next({
            message: this.notificationService.parseErrorTitle(error),
            detailedMessage: this.notificationService.parseDetailedMessage(error),
          });
          this.drImageSubject.next(null);
        }
      );
  }

  search(shipmentInstId: number, processStartDateTime: Date): void {
    this.refresh(false, false, shipmentInstId, processStartDateTime);
  }

  refresh(
    switchRoles: boolean = false,
    requestPrevious: boolean = false,
    startAtShipmentInstId = null,
    startAtProcessStartDateTime = null
  ): void {
    if (this.isRefreshingSubject.value) {
      return;
    }

    this.isRefreshingSubject.next(true);
    this.errorMessageSubject.next(undefined);

    if (switchRoles) {
      this.currentShipmentInstId = 0; // Force API to give appropriate new DR now that role has switched
      this.currentProNumber = null;
      this.currentProcessStartDateTime = null;

      this.previousShipmentInstIdSubject.next(0);
      this.previousProcessStartDateTime = null;
    }

    const queryParams = {
      ...new GetDeliveryShipmentForAuditQuery(),
      currentShipmentInstId:
        this.currentShipmentInstId <= 0 || requestPrevious ? undefined : this.currentShipmentInstId,
      fetchShipmentInstId:
        requestPrevious && this.previousShipmentInstIdSubject.value
          ? this.previousShipmentInstIdSubject.value
          : undefined,
      fetchProcessStartDateTime:
        requestPrevious && this.previousProcessStartDateTime ? this.previousProcessStartDateTime : undefined,
      //auditorTypeCd: this.getAuditorType(),
    };

    if (startAtShipmentInstId && startAtProcessStartDateTime) {
      queryParams.currentShipmentInstId = null;
      queryParams.fetchShipmentInstId = startAtShipmentInstId;
      queryParams.fetchProcessStartDateTime = startAtProcessStartDateTime;
    }

    this.cityOperationsService
      .getDeliveryShipmentForAudit(queryParams)
      .pipe(
        take(1),
        finalize(() => this.isRefreshingSubject.next(false))
      )
      .subscribe(
        (response: GetDeliveryShipmentForAuditResp) => {
          this.drRetrievalTime = moment.utc();
          this.drAcknowledgementTime = undefined;

          const newShipmentInstId = response?.deliverySpecialService?.shipmentInstId || 0;
          this.loggingService.log(
            LogLevel.INFO,
            `Last shipmentInstId ${this.currentShipmentInstId}, New shipmentInstId ${newShipmentInstId}`
          );

          if (this.firstFetch) {
            this.firstFetch = false;

            this.notificationService
              .openNotificationMessage(
                NotificationMessageStatus.Info,
                `When a new delivery receipt is loaded the pro number will automatically be added to your clipboard.`
              )
              .subscribe();
          }

          if (startAtShipmentInstId && startAtProcessStartDateTime) {
            this.previousShipmentInstIdSubject.next(0);
            this.previousProcessStartDateTime = null;
          } else {
            this.previousProcessStartDateTime = requestPrevious ? null : this.currentProcessStartDateTime;
            this.previousShipmentInstIdSubject.next(requestPrevious ? 0 : this.currentShipmentInstId);
          }

          this.currentProcessStartDateTime = response?.deliverySpecialService?.processStartDateTime;
          this.currentProNumber = response?.deliverySpecialService?.proNbr;
          this.clipboardService.copy(this.currentProNumber);

          this.currentShipmentInstId = newShipmentInstId;

          if (response?.auditMetrics) {
            this.metricsSubject.next({
              reviewedOverall: response.auditMetrics.completed,
              reviewedByYou: response.auditMetrics.audtisByEmployee,
              pending: response.auditMetrics.pending,
            });
          }

          if (response?.deliveryReceipt) {
            if (this.endOfWorkQueueSubject.value) {
              this.endOfWorkQueueSubject.next(false);
            }

            this.drImageSubject.next(decode(response.deliveryReceipt));
          } else {
            this.drImageSubject.next(null);

            if (!this.endOfWorkQueueSubject.value) {
              this.endOfWorkQueueSubject.next(true);
            }
          }

          this.rotateValueSubject.next(0);

          const deliverySpecialServiceAudit = response?.deliverySpecialServiceAudits?.find(
            (audit: DeliverySpecialServiceAudit) => audit.auditEmployeeId === this.userRoleService.getEmployeeId()
          );

          const initialFormState: InitialFormState = {
            isInsideDelivery: deliverySpecialServiceAudit?.specialServiceInsideInd ?? false,
            isLiftgateRequired: deliverySpecialServiceAudit?.specialServiceLiftgateInd ?? false,
            isResidential: deliverySpecialServiceAudit?.specialServiceResidentialInd ?? false,
            isConstructionUtilitySite: deliverySpecialServiceAudit?.specialServiceConstructionInd ?? false,
            otherNotes: this.buildOtherNotes(response),
          };

          this.refreshSubject.next(initialFormState);
        },
        (error) => {
          this.errorMessageSubject.next({
            message: this.notificationService.parseErrorTitle(error),
            detailedMessage: this.notificationService.parseDetailedMessage(error),
          });
          this.drImageSubject.next(null);
        }
      );
  }

  loadState(formGroup: FormGroup): void {
    this.formGroup = formGroup;

    const query = new GetUserPreferenceQuery();
    query.uiComponentName = this.UserPreferencesKey;
    this.userPreferencesService
      .getUserPreference(query)
      .pipe(take(1))
      .subscribe((response: GetUserPreferenceResp) => {
        if (response && response.preferences) {
          const settings: Settings = JSON.parse(response.preferences);
          this.zoomValueSubject.next(settings?.zoom || 0.5);
        }
      });
  }

  suppressKeyboardShortcuts(suppress: boolean): void {
    this.suppressKeyboardShortcutsSubject.next(suppress);
  }

  rotateLeft(): void {
    let r = this.rotateValueSubject.value;
    if (r <= 0) {
      r = 360;
    }
    r -= 90;
    if (r < 0) {
      r = 0;
    }
    this.rotateValueSubject.next(r);
  }

  rotateRight(degrees: number): void {
    let r = this.rotateValueSubject.value;
    r += degrees;
    if (r >= 360) {
      r = 0;
    }
    this.rotateValueSubject.next(r);
  }

  private saveState(): void {
    const request = new UpsertUserPreferenceRqst();
    request.uiComponentName = this.UserPreferencesKey;
    request.preferences = JSON.stringify(<Settings>{ zoom: this.zoomValueSubject.value });
    const query = new UpsertUserPreferenceQuery();
    this.userPreferencesService
      .upsertUserPreference(request, query)
      .pipe(take(1))
      .subscribe();
  }

  private getAuditorType(): EmployeeRoleCd {
    //const selectedRole: Role = this.formGroup.get(FormFields.Role).value;

    if (this.userRoleService.isProduction()) {
      return EmployeeRoleCd.DR_REVIEWER;
    } else {
      return EmployeeRoleCd.DR_TEST_REVIEWER;
    }
  }

  private buildOtherNotes(response: GetDeliveryShipmentForAuditResp): string {
    if (!response?.deliverySpecialService) {
      return '';
    }

    const statusCode = _toUpper(response?.deliverySpecialService?.shipmentAuditStatusCd ?? '');

    let audits: DeliverySpecialServiceAudit[] = response?.deliverySpecialServiceAudits?.filter(
      (audit) => audit.auditEmployeeId !== this.userRoleService.getEmployeeId()
    );

    audits = audits ? audits : [];

    const ind2img = (checked: boolean): string => {
      return checked ? './assets/checked.png' : './assets/unchecked.png';
    };

    const ind2class = (checked: boolean): string => {
      return checked ? 'audit-line-image-checked' : 'audit-line-image';
    };

    const status: string = `<div class="audit-line">${statusCode}</div>`;

    let auditorRecords: string = '';
    for (let audit of audits) {
      auditorRecords =
        auditorRecords +
        `<div class="audit-line">
          <bold>
          <div class="audit-line-emp">${audit.auditEmployeeName}</div>
          </bold>
          <div class="audit-line-label">
            <img
              class="${ind2class(audit.specialServiceInsideInd)}"
              src="${ind2img(audit.specialServiceInsideInd)}"
            />Inside Delivery
          </div>
          <div class="audit-line-label">
            <img
              class="${ind2class(audit.specialServiceLiftgateInd)}"
              src="${ind2img(audit.specialServiceLiftgateInd)}"
            />Liftgate Service
          </div>
          <div class="audit-line-label">
            <img
              class="${ind2class(audit.specialServiceResidentialInd)}"
              src="${ind2img(audit.specialServiceResidentialInd)}"
            />Residential Delivery
          </div>
          <div class="audit-line-label">
            <img
              class="${ind2class(audit.specialServiceConstructionInd)}"
              src="${ind2img(audit.specialServiceConstructionInd)}"
            />Construction/Utility Site
          </div>
        </div>`;
    }

    const divider: string = `<hr class="audit-separator" />`;

    let notes: string = '';
    for (let audit of audits) {
      notes =
        notes +
        `<div class="audit-line">
          <bold>
          <div class="audit-line-emp">
              ${audit.auditEmployeeName || audit.auditEmployeeId}
            </div>
          </bold>
          <div class="audit-note">
            ${audit.auditNote || 'No notes were provided.'}
          </div>
        </div>`;
    }

    return status + auditorRecords + divider + notes;
  }
}
