import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { toUpper as _toUpper } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { DRAuditorService } from '../../services/dr-auditor.service';

@Component({
  selector: 'app-end-of-queue-dialog',
  templateUrl: './end-of-queue-dialog.component.html',
  styleUrls: ['./end-of-queue-dialog.component.scss'],
  host: { class: 'app-EndOfQueue' },
  encapsulation: ViewEncapsulation.None,
})
export class EndOfQueueDialogComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  @HostListener('window:keyup', ['$event'])
  keyEventUp(event: KeyboardEvent) {
    console.log('key', event);
    if (event.keyCode === 13 || _toUpper(event.key) === 'ENTER' || _toUpper(event.code) === 'ENTER') {
      event.preventDefault();
      event.stopPropagation();

      this.refresh();
      return false;
    } else if (_toUpper(event.key) === 'X') {
      event.preventDefault();
      event.stopPropagation();

      this.cancel();
      return false;
    }
  }

  constructor(public dialogRef: MatDialogRef<EndOfQueueDialogComponent>, private drAuditorService: DRAuditorService) {}

  ngOnInit(): void {
    this.subscribeToExternalSources();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  refresh(): void {
    this.drAuditorService.refresh();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  private subscribeToExternalSources(): void {
    this.drAuditorService.endOfWorkQueue$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((endOfWorkQueue: boolean) => {
        if (!endOfWorkQueue) {
          this.dialogRef.close();
        }
      });
  }
}
