import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { XpoDialogModule, XpoButtonModule } from '@xpo-ltl/ngx-ltl-core';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { KeyboardShortcutsComponent } from './keyboard-shortcuts.component';
import { KeyboardKeyConverterPipe } from './pipes/keyboard-key-converter.pipe';

const pipes = [KeyboardKeyConverterPipe];
const components = [KeyboardShortcutsComponent];
@NgModule({
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  entryComponents: [...components],
  imports: [CommonModule, XpoButtonModule, MatTableModule, KeyboardShortcutsModule, XpoDialogModule],
})
export class AppKeyboardShortcutsModule {}
