<xpo-ltl-dialog-title
  [options]="{
    closeButton: false
  }"
>
  <div class="app-EndOfQueue-title"><mat-icon>warning</mat-icon>End of Queue Document Review</div>
</xpo-ltl-dialog-title>

<div mat-dialog-content class="app-EndOfQueue__content">
  <div class="app-EndOfQueue__content-detail">
    <div class="app-EndOfQueue__content-detail-disclaimer">
      You have reached the end of the documents review in your queue. Please refresh to see if more documents are
      available.
    </div>

    <div class="app-EndOfQueue__actionButtons">
      <button mat-flat-button (click)="refresh()" data-test="create-button">
        Refresh<span class="key">Key: Enter</span>
      </button>
      <button mat-stroked-button (click)="cancel()" data-test="close-button">
        Cancel<span class="key">Key: X</span>
      </button>
    </div>
  </div>
</div>
