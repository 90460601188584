import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { XpoNotificationTemplate } from '@xpo-ltl/ngx-ltl-core';
import {
  isObject as _isObject,
  has as _has,
  defaultTo as _defaultTo,
  get as _get,
  isEmpty as _isEmpty,
  isString as _isString,
  toString as _toString,
} from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ErrorDialogComponent } from '../../app/dr-audit/shared/components/error-dialog/error-dialog.component';
import { GenericErrorLazyTypedModel } from '../../app/dr-audit/shared/models/generic-error-lazy-typed.model';
import { ConfigManagerProperties, NotificationMessageResult, NotificationMessageStatus } from '../enums';

const DEFAULT_SNACKBAR_DURATION = 5000;

@Injectable({ providedIn: 'root' })
export class NotificationMessageService {
  constructor(
    private snackbar: XpoSnackBar,
    private configManagerService: ConfigManagerService,
    private dialog: MatDialog
  ) {}

  /**
   * Opens a snack bar (toast) message.
   */
  openSnackBar(
    message: string,
    status: NotificationMessageStatus,
    detailedMessage = ``,
    duration: number = DEFAULT_SNACKBAR_DURATION,
    action?: string,
    actionFunc?: Function
  ): MatSnackBarRef<XpoNotificationTemplate> {
    switch (status) {
      case NotificationMessageStatus.Success:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.successToastDuration);
        break;
      case NotificationMessageStatus.Error:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.errorToastDuration);
        break;
      case NotificationMessageStatus.Warn:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.warningToastDuration);
    }

    const matConfig: MatSnackBarConfig = {
      duration,
      verticalPosition: 'bottom',
      panelClass: ['app-Snackbar-bottom', `app-Snackbar-${status}`],
    };

    if (action) {
      return this.snackbar.open({
        message: message,
        detailedMessage,
        status: status,
        matConfig: matConfig,
        linkAction: {
          message: action,
          function: () => actionFunc(),
        },
      });
    }
    return this.snackbar.open({
      message: message,
      detailedMessage,
      status: status,
      matConfig: matConfig,
    });
  }

  /**
   * Opens a dialog (modal) with an error message and with more details about the error
   */
  openErrorDialog(error: GenericErrorLazyTypedModel): MatDialogRef<ErrorDialogComponent> {
    return this.dialog.open(ErrorDialogComponent, {
      data: error,
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'error-snackbar-panel',
    });
  }

  openNotificationMessage(
    status: NotificationMessageStatus,
    message?: GenericErrorLazyTypedModel | string,
    action?: string,
    actionFunc?: Function
  ): Observable<NotificationMessageStatus | NotificationMessageResult> {
    const error: GenericErrorLazyTypedModel = (message
      ? _isString(message)
        ? new GenericErrorLazyTypedModel({ error: { message: message } })
        : message
      : new GenericErrorLazyTypedModel()) as GenericErrorLazyTypedModel;

    return new Observable((observer) => {
      if (status === NotificationMessageStatus.Error && error.code === '503') {
        const dialogRef = this.openErrorDialog(error);
        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => {
            observer.next(status);
            observer.complete();
          });
      } else {
        const snackRef = this.openSnackBar(
          this.parseErrorTitle(error),
          status,
          this.parseDetailedMessage(error),
          DEFAULT_SNACKBAR_DURATION,
          action,
          actionFunc
        );
        snackRef
          .afterDismissed()
          .pipe(take(1))
          .subscribe((result) => {
            observer.next(status);
            observer.complete();
          });
      }
    });
  }

  // Parse error response from an API call.  Unfortunately, top level error response is not strongly typed yet.
  // An example error message:
  /*
  {
    "code":"400",
    "transactionTimestamp":1572446503417,
    "error":{
      "errorCode":"SCON021-596E",
      "message":"Validation Errors Found.",
      "trace":"at com.xpo.ltl.api.exception.AbstractExceptionBuilder.newValidationException(AbstractExceptionBuilder.java:304)",
      "moreInfo":[{
        "message":"Cannot reassign a stop to a trip that is not in New status",
        "location":"ReassignStopsValidator.validate"
      }]
    }}
  */
  parseErrorMessage(fault: GenericErrorLazyTypedModel | string | number): string {
    if (_toString(_get(fault, 'code', '')).startsWith('4')) {
      let message: string = _get(fault, 'error.message', '');
      _get(fault, 'error.moreInfo', []).forEach((moreInfo) => {
        if (!message.endsWith('.')) {
          message += '.';
        }
        message += ` ${moreInfo.message}`;
      });
      return message;
    } else {
      if (_has(fault, 'error.message')) {
        fault = _get(fault, 'error.message');
      } else if (_isObject(fault)) {
        const auxFault = _isEmpty(fault) ? undefined : JSON.stringify(fault);

        return _toString(_defaultTo(auxFault, 'Unknown Error'));
      }

      return _toString(_defaultTo(fault, 'Unknown Error'));
    }
  }

  parseErrorTitle(fault: GenericErrorLazyTypedModel | string | number): string {
    if (_has(fault, 'error.message')) {
      fault = _get(fault, 'error.message');
    } else if (_isObject(fault)) {
      const auxFault = _isEmpty(fault) ? undefined : JSON.stringify(fault);

      return _toString(_defaultTo(auxFault, 'Unknown Error'));
    }

    return _toString(_defaultTo(fault, 'Unknown Error'));
  }

  parseDetailedMessage(fault: GenericErrorLazyTypedModel | string | number): string {
    let message = '';
    if (_toString(_get(fault, 'code', '')).startsWith('4')) {
      message = _get(fault, 'error.moreInfo', [])
        .map((moreInfo) => moreInfo.message)
        .join('<br>');
    }

    return message;
  }
}
