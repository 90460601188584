import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { AppMetadataApiService, GetAppMetadataPath, GetAppMetadataResp } from '@xpo-ltl/sdk-appmetadata';
import { parseInt as _parseInt } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ConfigManagerProperties } from '../../enums';

export interface IMinAppVersionResponse {
  meetsMinimumVersion: boolean;
  currentVersion?: string;
  minimumVersion?: string;
}

export class VersionGuardBase {
  private majorMinorBuildRegex = new RegExp('^([0-9]+).([0-9]+).([0-9]+)(?:\\D*)$');
  private majorMinorRevisionBuildRegex = new RegExp('^([0-9]+).([0-9]+).([0-9]+).([0-9]+)(?:\\D*)$');
  private matchGroups = { Major: 1, Minor: 2, Revision: 3, Build: 4 };

  constructor(
    protected appMetadataApiService: AppMetadataApiService,
    protected configManager: ConfigManagerService,
    protected router: Router
  ) {}

  protected isMinimumVersionMet(): Observable<IMinAppVersionResponse> {
    const currentVersion: string = this.configManager.getSetting(ConfigManagerProperties.buildVersion);

    if (currentVersion === 'local-version') {
      return of({
        meetsMinimumVersion: true,
      } as IMinAppVersionResponse);
    }

    const pathParams: GetAppMetadataPath = {
      ...new GetAppMetadataPath(),
      appName: this.configManager.getSetting(ConfigManagerProperties.appMetadataAppName),
    };

    return this.appMetadataApiService.getAppMetadata(pathParams).pipe(
      map((resp: GetAppMetadataResp) => {
        const meetsMinimumVersion = this.meetsMinimumVersionRequirement(resp.minAppVersion, currentVersion);

        return {
          meetsMinimumVersion,
          currentVersion,
          minimumVersion: resp.minAppVersion,
        } as IMinAppVersionResponse;
      }),
      catchError(() =>
        of({
          meetsMinimumVersion: true,
        } as IMinAppVersionResponse)
      )
    );
  }

  private meetsMinimumVersionRequirement(minVersion: string, version: string): boolean {
    let meets = false;
    if (
      minVersion &&
      version &&
      minVersion.trim().length > 0 &&
      version.trim().length > 0 &&
      minVersion.match(/./g).length >= 2 &&
      version.match(/./g).length >= 2
    ) {
      if (minVersion === version) {
        meets = true;
      } else {
        const versionDotsLength = (version.match(/\./g) || []).length;
        const minVersionDotsLength = (minVersion.match(/\./g) || []).length;

        if (versionDotsLength === 0 || minVersionDotsLength === 0) {
          return false;
        }

        const versionResults = (versionDotsLength === 2
          ? this.majorMinorBuildRegex
          : this.majorMinorRevisionBuildRegex
        ).exec(version);
        const minVersionResults = (minVersionDotsLength === 2
          ? this.majorMinorBuildRegex
          : this.majorMinorRevisionBuildRegex
        ).exec(minVersion);

        if (!versionResults || !minVersionResults) {
          return false;
        }

        while (versionResults.length < 5) {
          versionResults.push('0');
        }
        while (minVersionResults.length < 5) {
          minVersionResults.push('0');
        }

        if (
          _parseInt(versionResults[this.matchGroups.Major]) === _parseInt(minVersionResults[this.matchGroups.Major]) &&
          _parseInt(versionResults[this.matchGroups.Minor]) === _parseInt(minVersionResults[this.matchGroups.Minor]) &&
          _parseInt(versionResults[this.matchGroups.Revision]) ===
            _parseInt(minVersionResults[this.matchGroups.Revision]) &&
          _parseInt(versionResults[this.matchGroups.Build]) === _parseInt(minVersionResults[this.matchGroups.Build])
        ) {
          meets = true;
        } else {
          if (
            _parseInt(versionResults[this.matchGroups.Major]) > _parseInt(minVersionResults[this.matchGroups.Major])
          ) {
            meets = true;
          } else if (
            _parseInt(versionResults[this.matchGroups.Major]) < _parseInt(minVersionResults[this.matchGroups.Major])
          ) {
            meets = false;
          } else if (
            _parseInt(versionResults[this.matchGroups.Minor]) > _parseInt(minVersionResults[this.matchGroups.Minor])
          ) {
            meets = true;
          } else if (
            _parseInt(versionResults[this.matchGroups.Minor]) < _parseInt(minVersionResults[this.matchGroups.Minor])
          ) {
            meets = false;
          } else if (
            _parseInt(versionResults[this.matchGroups.Revision]) >
            _parseInt(minVersionResults[this.matchGroups.Revision])
          ) {
            meets = true;
          } else if (
            _parseInt(versionResults[this.matchGroups.Revision]) <
            _parseInt(minVersionResults[this.matchGroups.Revision])
          ) {
            meets = false;
          } else if (
            _parseInt(versionResults[this.matchGroups.Build]) > _parseInt(minVersionResults[this.matchGroups.Build])
          ) {
            meets = true;
          } else if (
            _parseInt(versionResults[this.matchGroups.Build]) < _parseInt(minVersionResults[this.matchGroups.Build])
          ) {
            meets = false;
          }
        }
      }
    }
    return meets;
  }
}
