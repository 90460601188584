import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';
import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

LicenseManager.setLicenseKey(
  'XPO_Enterprise_Services,_Inc._MultiApp_10Devs17_October_2020__MTYwMjg4OTIwMDAwMA==36c4e9daaa48e94dc3320e2fd511dd5b'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
