import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { RoleSwitchGuard } from 'core/guards';

import { environment } from '../environments/environment';

// Skip SSO when running in Cypress test environment
const skipCanActivate = environment.cypress;

const RouterDefinitions: Routes = [
  {
    path: 'version-mismatch',
    canActivate: skipCanActivate ? [] : [XpoAuthenticationGuard],
    loadChildren: () => import('../app/version-mismatch/version-mismatch.module').then((m) => m.VersionMismatchModule),
  },
  {
    path: 'docview',
    canActivate: skipCanActivate ? [] : [XpoAuthenticationGuard],
    loadChildren: () => import('./doc-view/doc-view.module').then((m) => m.DocviewModule),
  },
  {
    path: 'shipment-details',
    canActivate: skipCanActivate ? [] : [XpoAuthenticationGuard],
    loadChildren: () => import('./shipment-details/shipment-details.module').then((m) => m.ShipmentDetailsModule),
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: skipCanActivate ? [] : [XpoAuthenticationGuard, RoleSwitchGuard],
    loadChildren: () => import('./dr-audit/dr-audit.module').then((m) => m.DRAuditModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
