import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { AppMetadataApiService } from '@xpo-ltl/sdk-appmetadata';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IMinAppVersionResponse, VersionGuardBase } from '../version-guard-base.class';

@Injectable({
  providedIn: 'root',
})
export class ApplicationMinimumVersionGuard extends VersionGuardBase implements CanActivate {
  constructor(appMetadataApiService: AppMetadataApiService, configManager: ConfigManagerService, router: Router) {
    super(appMetadataApiService, configManager, router);
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.isMinimumVersionMet().pipe(
      map(({ meetsMinimumVersion, currentVersion, minimumVersion }: IMinAppVersionResponse) => {
        if (!meetsMinimumVersion) {
          this.router.navigate(['/version-mismatch'], {
            replaceUrl: true,
            queryParams: {
              currentVersion,
              minimumVersion,
            },
          });
        }
        return meetsMinimumVersion;
      })
    );
  }
}
