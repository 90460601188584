<xpo-ltl-dialog-title
  [options]="{
    closeButton: false
  }"
>
  <div class="app-RoleChange-title"><mat-icon>warning</mat-icon>{{ title }}</div>
</xpo-ltl-dialog-title>

<div mat-dialog-content class="app-RoleChange__content">
  <div class="app-RoleChange__content-detail">
    <div class="app-RoleChange__content-detail-disclaimer">
      {{ disclaimer }}
    </div>

    <div class="app-RoleChange__actionButtons">
      <button mat-flat-button (click)="proceed()" data-test="create-button">
        Proceed
      </button>
      <button mat-stroked-button (click)="cancel()" data-test="close-button">
        Cancel
      </button>
    </div>
  </div>
</div>
