import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface RoleChangeDialogData {
  isFormDirty: boolean;
}

@Component({
  selector: 'app-role-change-dialog',
  templateUrl: './role-change-dialog.component.html',
  styleUrls: ['./role-change-dialog.component.scss'],
  host: { class: 'app-RoleChange' },
  encapsulation: ViewEncapsulation.None,
})
export class RoleChangeDialogComponent implements OnInit {
  disclaimer: string = '';
  title: string = '';

  constructor(
    public dialogRef: MatDialogRef<RoleChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RoleChangeDialogData
  ) {}

  ngOnInit(): void {
    this.title = this.data.isFormDirty ? `Save & Proceed with Role Change?` : 'Proceed with Role Change?';

    this.disclaimer = this.data.isFormDirty
      ? `You have changed your role since updates were made to this DR-OCR. Proceeding will automatically save the changes
    you made.`
      : `Proceeding will automatically retrieve the next DR to review.`;
  }

  proceed(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
